import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {NewsResponse, RoadmapResponse} from "./responses";
import {environment} from "../../environments/environment";

@Injectable()
export class I18nService {

    constructor(
        private http: HttpClient,
    ) {
    }

    getTranslations(language: string, key: string): Observable<I18nTranslations> {
        return this.http.get<I18nTranslations>(`${environment.apiUrl}/i18n/${language}?key=${key}`)
    }

    postTranslation(language: string, key: string, id: string, value: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}/i18n/${language}/translation?key=${key}`, {id: id, value: value})
    }
}

export type I18nTranslations = {
    translations: I18nTranslation[]
}

export type I18nTranslation = {
    id: string
    defaultValue: string
    value: string
    aiReviewed: boolean
    humanReviewed: boolean

    // Not in the API, but used in the frontend
    originalValue?: string
    error?: string
}
