import {enableProdMode, importProvidersFrom} from '@angular/core';

import {environment} from './environments/environment';
import {AppComponent} from './app/app.component';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import {BrowserModule, bootstrapApplication} from '@angular/platform-browser';
import {provideAnimations} from "@angular/platform-browser/animations";
import {provideRouter, withComponentInputBinding} from "@angular/router";
import {routes} from "./routes";
import {provideMarkdown} from "ngx-markdown";
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {ErrorInterceptorService} from "./app/services/error-interceptor.service";
import {LandingService} from "./app/services/landing.service";
import {I18nService} from "./app/services/i18n.service";

const cookieConfig: NgcCookieConsentConfig = {
    "cookie": {
        "domain": "www.ludoya.com"
    },
    "position": "bottom",
    "theme": "classic",
    "palette": {
        "popup": {
            "background": "#000000",
            "text": "#ffffff",
            "link": "#ffffff"
        },
        "button": {
            "background": "#f1d600",
            "text": "#000000",
            "border": "transparent"
        }
    },
    "type": "info",
    "content": {
        "message": "This website uses cookies to ensure you get the best experience on our website.",
        "dismiss": "Got it!",
        "deny": "Refuse cookies",
        "link": "Learn more",
        "href": "https://cookiesandyou.com",
        "policy": "Cookie Policy"
    }
};

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        LandingService,
        I18nService,
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true},
        importProvidersFrom(BrowserModule, NgcCookieConsentModule.forRoot(cookieConfig)),
        provideAnimations(),
        // add input binding feature
        provideRouter(routes, withComponentInputBinding()),
        provideHttpClient(withInterceptorsFromDi()),
        provideMarkdown(),
        //provideAnimationsAsync(),
    ]
})
    .catch(err => console.error(err));
